.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.login-div {
  /* min-width: 500px; */
  margin: 0px 20px;
  flex: auto;
}
.my-list-item {
  padding: 1em 3em !important;
}
@media (min-width: 700px) {
  .login-form {
    padding: 2em 4em !important;
  }
}

.login-form {
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1), 0px 8px 8px rgba(0, 0, 0, 0.15),
    0px 2px 2px rgba(0, 0, 0, 0.5);
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 40px;
  padding-bottom: 20px;
  background: #fff;
  transition: all 150ms ease-in;
}
.login-form:hover {
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.2), 0px 8px 8px rgba(0, 0, 0, 0.25),
    0px 2px 2px rgba(0, 0, 0, 0.6);
  transform: scale(1.015);
  /* padding: 60px; */
}
footer {
  min-height: 60px;
  margin-top: auto;
}
.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.logout {
  cursor: pointer;
  position: absolute;
  right: 110px;
  top: 30px;
}

.tableFixHead {
  overflow-y: auto;
  height: 100px;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
}

tr :hover {
  cursor: pointer;
}
.header-img {
  width: 60px;
}

.form-css {
  width: 50vw;
  margin: auto;
  margin-top: 1%;
  margin-bottom: 1%;
  box-shadow: 2px 3px 4px 6px rgba(0, 0, 0, 0.15);
}
@media (max-width: 1024px) {
  .form-css {
    width: 100vw;
    box-shadow: none;
  }
}

@media (max-width: 546px) {
  .header-img {
    width: 35px !important;
  }
  .logout {
    right: 80px;
    top: 25px;
  }
}

@media (max-width: 435px) {
  .logout {
    /* right: auto; */
    /* right: auto; */
    left: auto;
    right: 45vw;
    top: 50px;
  }
}

input {
  border-top: none;
  border-right: none;
  border-left: 2px solid #8c8c8c;
  border-bottom: 3px solid black;
  padding-left: 1%;
  margin-left: 1.8% !important;
}

input:focus {
  outline: none;
  border-left: none;
}

.form-text {
  padding: 10px;
  height: auto;
}
.form-text:hover {
  background-color: rgb(241, 243, 247);
  cursor: pointer;
  box-shadow: 0px 5px 5px rgba(105, 105, 105, 0.2),
    0px 4px 4px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.6);
  transform: scale(1.02);
}

:checked {
  /* margin-left: 10px; */
  transform: scale(1.35);
  /* border-color: black; */
}
